import { jsx as _jsx } from "react/jsx-runtime";
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import dashboard from '../../../assets/icons/navbar/ic_dashboard.svg';
import patient from '../../../assets/icons/navbar/ic_patient.svg';
import laboratoires from '../../../assets/icons/navbar/ic_laboratoires.svg';
import cabinet from '../../../assets/icons/navbar/ic_cabinet.svg';
import config from '../../../assets/icons/navbar/ic_config.svg';
import permission from '../../../assets/icons/navbar/ic_permission.svg';
import archive from '../../../assets/icons/navbar/ic_archive.svg';
import notifications from '../../../assets/icons/navbar/ic_notifications.svg';
import darftOrders from '../../../assets/icons/navbar/ic_draft_orders.svg';
import prosthetist from '../../../assets/icons/navbar/ic_prosthetist.svg';
import order from '../../../assets/icons/navbar/ic_orders.svg';
import dentist from '../../../assets/icons/navbar/ic_dentists.svg';
import calendar from '../../../assets/icons/navbar/ic_calendar.svg';
// ----------------------------------------------------------------------
const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: '',
        items: [
            {
                title: 'tableau de bord',
                path: PATH_DASHBOARD.app,
                icon: _jsx(SvgColor, { src: dashboard }),
                author: ['PROTHESIST', 'DENTIST', 'SUPER_ADMIN'],
            },
            {
                title: 'notifications',
                path: PATH_DASHBOARD.notifications,
                icon: _jsx(SvgColor, { src: notifications }),
                author: ['SUPER_ADMIN', 'PROTHESIST', 'DENTIST'],
            },
            {
                title: 'Prothésiste',
                path: PATH_DASHBOARD.prosthetist,
                icon: _jsx(SvgColor, { src: prosthetist }),
                author: ['SUPER_ADMIN'],
                entity: 'Prothesist',
            },
            {
                title: 'Dentist',
                path: PATH_DASHBOARD.dentist,
                icon: _jsx(SvgColor, { src: dentist }),
                author: ['SUPER_ADMIN'],
            },
            {
                title: 'patient',
                path: PATH_DASHBOARD.patient,
                icon: _jsx(SvgColor, { src: patient }),
                author: ['PROTHESIST', 'DENTIST', 'SUPER_ADMIN'],
                entity: 'Patient',
            },
            {
                title: 'laboratoires',
                path: PATH_DASHBOARD.laboratoires,
                icon: _jsx(SvgColor, { src: laboratoires }),
                author: ['SUPER_ADMIN'],
            },
            {
                title: 'cabinet',
                path: PATH_DASHBOARD.cabinet,
                icon: _jsx(SvgColor, { src: cabinet }),
                author: ['PROTHESIST', 'SUPER_ADMIN'],
                entity: 'Cabinet',
            },
            {
                title: 'Brouillon du commandes',
                path: PATH_DASHBOARD.draftCommand,
                icon: _jsx(SvgColor, { src: darftOrders }),
                author: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'],
                entity: 'Prothese',
            },
            {
                title: 'commandes',
                path: PATH_DASHBOARD.orders,
                icon: _jsx(SvgColor, { src: order }),
                author: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'],
                entity: 'Command',
            },
            {
                title: 'calendar',
                path: PATH_DASHBOARD.calendar,
                icon: _jsx(SvgColor, { src: calendar }),
                author: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'],
                entity: 'Command',
            },
            {
                title: 'configuration',
                path: PATH_DASHBOARD.config.root,
                icon: _jsx(SvgColor, { src: config }),
                children: [
                    {
                        title: 'Options',
                        path: PATH_DASHBOARD.config.options,
                        author: ['SUPER_ADMIN'],
                        entity: 'Option',
                    },
                    {
                        title: 'materiaux',
                        path: PATH_DASHBOARD.config.materiaux,
                        author: ['SUPER_ADMIN'],
                        entity: 'Material',
                    },
                    {
                        title: 'types',
                        path: PATH_DASHBOARD.config.types,
                        author: ['SUPER_ADMIN'],
                        entity: 'Type',
                    }
                ],
                author: ['SUPER_ADMIN'],
            },
            {
                title: 'permission',
                path: PATH_DASHBOARD.permission,
                icon: _jsx(SvgColor, { src: permission }),
                author: ['SUPER_ADMIN'],
                entity: 'Role',
            },
            {
                title: 'archive',
                path: PATH_DASHBOARD.archive.root,
                icon: _jsx(SvgColor, { src: archive }),
                children: [
                    {
                        title: 'patient',
                        path: PATH_DASHBOARD.archive.patient,
                        author: ['DENTIST', 'PROTHESIST', 'SUPER_ADMIN'],
                        entity: 'ArchivedPatient',
                    },
                    {
                        title: 'cabinet',
                        path: PATH_DASHBOARD.archive.cabinet,
                        author: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'],
                        entity: 'ArchivedCommand',
                    },
                    {
                        title: 'commandes',
                        path: PATH_DASHBOARD.archive.order,
                        author: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'],
                        entity: 'ArchivedCommand',
                    },
                ],
                author: ['SUPER_ADMIN', 'PROTHESIST', 'DENTIST'],
            },
        ],
    },
];
export default navConfig;
